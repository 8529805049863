.input {
  width: 100%;
  min-height: 3rem;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #444;
  background: #FFFFFF;
  border: 1px solid #A8A8A8;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;

  &_type_search {
    padding-left: 2.5rem;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);

    &-svg {
      display: block;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
