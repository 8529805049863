.slots-bets-filter {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__wrapper { width: 100% }
  &__title { margin-right: 3rem }

  &__filters {
    width: 90%;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 1fr;
    grid-row-gap: 1rem;
    p{
      display: inline-flex;
      align-items: center;
      text-align: center;
      margin-bottom: .2rem;
    }
  }

  &__input{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button{
    width: 60%;
  }

  &__calendar-icon {
    display: flex;
    align-items: center;
    position: absolute;
    right: .8rem;
    z-index: 1;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .wrapped-react-input{
    width: 100%;
    border: 1px solid #A8A8A8;
    padding-left: .5rem;
    background: #fff;
    border-radius: 5px;

    .react-date-picker {
      &__wrapper {
        border: none;
        flex-grow: 1;
      }
      &__inputGroup {
        &__year { width: 2rem }
      }
    }
  }
}