.transaction-history {
  padding-bottom: 2rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #444;
  &__filter {
    &-block {
    }
    &-item {
      padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    &-title {
      margin-bottom: 0.5rem;
    }
  }

  &__input {
    border: 1px solid #C0C0C0;
    background: transparent;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__calendar {
    position: absolute;
    top: calc(505 - 0.85rem);
    right: 0.5rem;
    width: 1.7143rem;
    height: 1.7143rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1rem;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      width: 100%;
      height: 2.625rem;
      outline: none;
      border: none;
      padding: 0;
      padding-left: 1.5rem;
      box-sizing: border-box;
      background: transparent;
      color: #fff;
    }
  }

  &__search-button {
    width: 64%;
    margin: 3rem auto;
  }

  &__search-block {
    margin-bottom: 1.357rem;
    padding: 0.64rem 0.64rem 0.64rem 1.286rem;
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    border-radius: 5px;
    color: #fff;
  }
  &__search-text {
    margin: 0.5rem;
  }

  &__users-header {
    height: 2.5625rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0 1rem;
    background: #1C1C1C;
    border: 0.925px solid #087DFF;
    border-radius: 5px;
  }
  &__result-wrapper {
    padding: 0 3px;
  }

  &__user-header-item {
    font-size: 0.7143rem;
    width: 100%;
    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
        max-width: 15%;
      }
    }
  }

  .transaction-item:nth-child(2n) {
    background: #f8f8f8f8;
  }
}