.menu-item {
  width: 90%;
  height: 2.8rem;
  margin-bottom: 0.714rem;
  color: #fff;
  background: linear-gradient(180deg, #242424 0%, #505050 100%);
  border: 1px solid #A8A8A8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;
  font-weight: 700;
  cursor: pointer;

  &__image {
    transition: all 0.3s ease-in-out;
    &-svg {
      display: block;
      width: 1rem;
    }

    &_open {
      transform: rotate(180deg);
    }

    &_type_away {
      &-svg {
        width: 0.585rem;
        * {
          fill: #444;
        }
      }
    }
  }
  &__element-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__element {
    width: 80%;
    height: 2.5625rem;
    font-size: 14px;
    font-weight: 700;
    color: #444;
    background: linear-gradient(180deg, #FDFDFD 0%, #DADADA 100%);
    border: 1px solid #A8A8A8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    margin-bottom: 0.25rem;
  }
}
