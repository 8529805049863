.total {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  &__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #FFF;
    padding-left: 0.7rem;
    box-sizing: border-box;
    justify-content: center;
  }
  &__input-block {
    padding: .5rem;
    margin-top: .4rem;
    box-sizing: border-box;
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    width: 74.25%;
    margin: 3rem auto;
    box-sizing: border-box;
  }
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background: #FFD500;
    height: 2.625rem;
    width: 64%;
    margin: 0.5rem auto;
    cursor: pointer;
    &_isLoading {
      // background: #11541c;
      cursor: not-allowed;
    }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    height: 3rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    color: #fff;
    margin-bottom: 1.25rem;
    background: #1C1C1C;
    border: 1px solid #087DFF;
    border-radius: 5px;
  }
  &__result {
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  &__row {
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.7rem;
    background: #FFFFFF;
    border: 1px solid #A8A8A8;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 8px;
  }
  &__td {
    width: 20%;
    &_type_left {
      width: 40%;
    }
  }
}
