.auth {
  &__logo {
    display: block;
    width: 17.2rem;
    height: 15.36rem;
    margin: 0.6rem auto;

    @media(min-width: 640px) {
      display: none;
    }
  }

  &__title {
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    width: 100%;
    height: 2.8125rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: bold;
  }
  &__title-text {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.625rem;
  }
  &__login-img {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 1.125rem;
  }
  &__input-block {
    padding: 1rem .7rem;
    box-sizing: border-box;
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
    display: flex;
    justify-content: center;
  }
  &__button {
    width: 70%;
    height: 2.8rem;
    background: linear-gradient(180deg, #AF7908 0%, #F2E074 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;

    &_disabled {
      filter: grayscale(1);
    }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}