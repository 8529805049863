.table {
  width: 90%;
  margin: 2rem auto 0 auto;

  &__row {
    border: 1px solid #fff;
    border-radius: 5px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    margin-bottom: .5rem;
    justify-content: space-between;
    padding: 1rem;
    background-color: #fff;
    &-p {
      margin-bottom: .5rem;
      &:last-child{ margin-bottom: 0 }
    }
    &-span {
      background: linear-gradient(180deg, #AF7908 0%, #F2E074 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &:last-child { margin-bottom: 0 }
    &-part_one{ margin-right: 1rem }
  }
  &__no-bets { text-align: center }
}