.bet-types-filter {
  width: 90%;
  margin: 1rem auto 0 auto;
  color: #444;

  &__filter-row {
    width: 100%;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    width: 100%;
    font-size: 1rem;
    margin-bottom: .2rem;
  }
}