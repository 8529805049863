.select {
  position: relative;
  width: 100%;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border: 1px solid #A8A8A8;
  border-radius: 5px;
  box-sizing: border-box;
  background: #fff;

  &_size_high {
    height: 3rem;
  }

  &_color_dark {
    background: #323946;
    color: #fff;
    border-radius: 4px;
  }

  &_open {
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }

  &__item {
    width: 100%;
    height: 3rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 1.0938rem;
    box-sizing: border-box;

    &_size_high {
      height: 3rem;
    }

    &:hover {
      background: #949494;
    }

    &_current:hover {
      background: none;
    }
  }

  &__arrow {
    width: 1rem;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1rem;
    }
  }

  &_open &__arrow {
    transform: scale(-1);
  }

  &__items-list {
    position: absolute;
    border-top: none;
    background: #ffffff;
    box-sizing: border-box;
    z-index: 2;
    max-height: 21rem;
    overflow: auto;
    top: 2.8rem;
    left: -1px;
    width: calc(100% + 2px);
    border: 1px solid #A8A8A8;
    border-radius: 0 0 5px 5px;

    &_color_dark {
      background: #323946;
      color: #fff;
      border-radius: 0 0 4px 4px;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 90%;
      scroll-margin-right: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background: #c4c4c4;
      cursor: pointer;
    }
  }
}