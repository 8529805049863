.users-list {
  display: flex;
  flex-direction: column;
  padding: 2.7% 4.3%;
  color: #444;
  font-weight: 700;

  &__title {
    margin-bottom: 1.428rem;
    font-size: 1.143rem;
    text-align: center;
  }
  &__header {
    margin-bottom: 1.357rem;
    padding: 0.64rem 0.64rem 0.64rem 1.286rem;
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    border-radius: 5px;
    color: #fff;
  }
  &__header-text {
    margin-bottom: 0.5rem;
  }
  &__filter-block {
    padding: 0.7rem;
    position: relative;
    width: 87%;
    align-self: center;
    margin-bottom: 2.5rem;
    justify-content: center;
    display: flex;
  }
  &__search-icon {
    position: absolute;
    left: 1.5rem;
    top: calc(50% - 0.5rem);
    &-svg {
      width: 1rem;
    }
  }

  &__subheader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 1.25rem;
    &-text, &-count {
      width: 100%;
      height: 1.4286rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8571rem;
      padding: 0 0.9rem;
      box-sizing: border-box;
    }
    &-text {
      color: #fff;
      background: #585858;
    }
  }
  &__bold-text {
    font-weight: bold;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5625rem;
    padding: 0 1.5rem;
    background: linear-gradient(180deg, #FDFDFD 0%, #DADADA 100%);
    border: 1px solid #A8A8A8;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 4px;
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;

    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1.0714rem;
      height: 1.0714rem;
      * {
        fill: #444;
      }
    }
  }

  &__list {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &__user-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: .5rem;
    box-sizing: border-box;
    width: 50%;
    margin-right: 1rem;
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    border: 0.925px solid #A8A8A8;
    border-radius: 5px;
    color: #fff;
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__account-balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    height: 2.5625rem;
    padding: .5rem;
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    border: 0.925px solid #A8A8A8;
    border-radius: 5px;
    box-sizing: border-box;
    width: 50%;
    color: #fff;
    &-balance {
      margin-right: 0.7rem;
      font-weight: bold;

    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

}