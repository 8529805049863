.change-pas {
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 2rem;
  padding-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;

  &__title {
    margin-bottom: 1rem;
    font-size: 1.143rem;
  }

  &__text-pass {
    margin-bottom: 0.5rem;
  }

  &__button {
    margin-top: 5rem;
    width: 80%;
    height: 3rem;
  }
}
