.cashier-jackpot {
  color: #444;
  padding: 0 1.125rem;
  box-sizing: border-box;
  margin: 0 0 2.3125rem 0;
  font-weight: 700;
  font-size: 1rem;

  &__type-switcher {
    width: 100%;
    height: 2.5625rem;
    display: flex;
    margin: 1rem 0 1.5625rem 0;
    background: #A8A8A8;
    box-sizing: border-box;
    border-radius: 0.3125rem;
  }
  &__type-switcher-item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    text-transform: capitalize;

    &_active {
      background: linear-gradient(180deg, #AF7908 0%, #F2E074 100%);
      border-radius: 0.313rem;
    }
  }

  &__start-amount {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.75rem 0;
  }
  &__start-amount-title {
    margin: 0 0 0.6875rem 0;
    text-align: start;
    font-weight: 700;
    font-size: 0.875rem;
  }
  &__start-amount-value {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 1.5625rem;
    background: #FFFFFF;
    border: 1px solid #A8A8A8;
    box-sizing: border-box;
    border-radius: 0.3125rem;
  }

  &__title {
    width: 100%;
    margin: 0 0 1.0625rem 0;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
  }

  &__inputs {
    width: 100%;
    margin: 0 0 2.5625rem 0;
  }
  &__input-date {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1.125rem;
    }
  }
  &__input-date-label {
    margin: 0 0 0.6875rem 0;
  }
  &__input-date-value {
    width: 100%;
    height: 3rem;
  }

  & .react-datepicker-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__custom-date-input {
    cursor: pointer;
    background: #fff;
    border-radius: 0.313rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1.3125rem;
    border: 1px solid #A8A8A8;
    border-radius: 5px;
  }
  &__custom-date-input-value {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
  }
  &__custom-date-input-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1.3125rem;
      height: 1.3125rem;

      & svg {
        fill: white;
      }
    }
  }

  &__button-container {
    width: 73%;
    display: flex;
    justify-content: center;
    margin: 0 auto 1.25rem auto;
  }

  &__list {
    width: 100%;
    margin: 0 0 2.125rem 0;
  }
  &__list-header {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 0.8fr 1fr 0.8fr 0.9fr;
    margin-bottom: 1.3125rem;
    padding: 0.375rem 0.625rem 0.4375rem 0.8125rem;
    color: #fff;
    background: #1C1C1C;
    border-radius: 0.3125rem;
  }
  &__list-header-item {
    min-width: 0;
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;

    &_type {
      &_left {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_right {
        text-align: right;
      }
    }
  }
  &__list-row {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 0.8fr 1fr 0.8fr 0.7fr;
    padding: 0.375rem 0.625rem 0.4375rem 0.8125rem;
    color: #444;
    background: #fff;
    border: 1px solid #A8A8A8;
    border-radius: 0.3125rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  &__list-row-item {
    min-width: 0;
    max-width: 100%;
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    overflow: auto;

    &_type {
      &_left {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_right {
        text-align: right;
      }
    }
  }

  &__paginator {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
