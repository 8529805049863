.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 2rem 1rem;
  box-sizing: border-box;
  margin-top: auto;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  &__item {
    width: 94%;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: #444;
    background: linear-gradient(180deg, #FDFDFD 0%, #DADADA 100%);
    border: 1px solid #A8A8A8;
    box-sizing: border-box;
    margin-bottom: 0.3rem;
    cursor: pointer;
  }
  &__image {
    width: 2rem;
    margin-right: 1rem;
  }
  &__title {
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    width: 100%;
    height: 2.9rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 1.5rem;
    box-sizing: border-box;
    font-size: 1.143rem;
    margin-bottom: 0.7rem;
  }
}
