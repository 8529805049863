.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 0.75rem;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  user-select: none;
  text-align: center;
  background: linear-gradient(180deg, #AF7908 0%, #F2E074 100%);
  color: #fff;
  outline: none;
  border-radius: 5px;

  &:active {
    transform: translateY(2px);
  }

  &_colors {
    &_black {
      background: linear-gradient(180deg, #242424 0%, #505050 100%);
    }

    &_grey {
      background: #A8A8A8;
    }
  }

  &_sizable {
    &_default {
      height: 2.625rem;
    }

    &_low {
      height: 2.5rem;
    }

    &_high {
      height: 3rem;
    }

    &_sign {
      height: 2rem;
    }
  }

  &_disable {
    background: #121931;
    cursor: not-allowed;
  }
}