.locale {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.75rem;
  font-weight: 700;
  font-size: 1rem;
  &__title {
    width: 90%;
    height: 3rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-size: 1.143rem;
    margin-bottom: 8px;
    justify-content: center;
    background: linear-gradient(180deg, #242424 0%, #505050 100%);
    border-radius: 5px;
  }
  &__item {
    width: 80%;
    height: 3rem;
    display: flex;
    align-items: center;
    padding-left: 0.7rem;
    margin-bottom: 4px;
    background: linear-gradient(180deg, #FDFDFD 0%, #DADADA 100%);
    border: 1px solid #A8A8A8;
    border-radius: 5px;
    box-sizing: border-box;
    color: #444;
    &_active {
      background: #A8A8A8;
      // background: linear-gradient(180deg, #AF7908 0%, #F2E074 100%);
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    margin-top: 2rem;
    box-sizing: border-box;
    padding: 1.7em 1em;
    width: 65%;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}