.password-pop-up {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;
  font-size: 1rem;
  font-weight: bold;
  &__body {
    background: #707070;
    width: 65%;
    height: 10rem;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__header {
    width: 100%;
    text-align: center;
  }
  &__password {
    font-weight: bold;
    font-size: 1.2857rem;
    text-align: center;
  }

  &__button {
    width: 62%;
    background: #FFD500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    color: #000;
    font-weight: bold;
    cursor: pointer;
  }
}