.bets {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1rem;
  &__user-id-input{
    margin: 0 auto;
    width: 90%;
    input{
      margin-top: .2rem;
      border-radius: 5px;
    }
  }
  &__pagination{
    width: 100%;
  }
  &__filter-row {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    width: 100%;
    font-size: 1rem;
    margin-bottom: .2rem;
  }
}